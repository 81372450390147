var $ = jQuery.noConflict();
$(document).ready(function() {
	$('div.carousel-inner > div > div > div').children('img').addClass('img-responsive');
	$('#views-bootstrap-carousel-1').addClass('well well-lg');
	// $('.carousel-inner').addClass('well well-lg');
	$('div.ts_circle').children('img').addClass('img-circle');
	$('.tab-pane').addClass('fade');
	$('#tab-1-0').addClass('active in');
	$('#tab-2-0').addClass('active in');
	$('#tab-3-0').addClass('active in');
	// $('body').scrollspy({ target: '.navbar-collapse'});
	//Bootstrap mobile navbar collapse.
	$(".navbar-nav li a").click(function(event) {
    $(".navbar-collapse").collapse('hide');
  	});


	// Backgrounds
	// $('#ts_intro').anystretch("sites/all/themes/ipad/css/images/home.jpg", {speed: 150});
	// $('#ts_synopsis').anystretch("sites/all/themes/ipad/css/images/Synopsis.jpg", {speed: 150});
	// $('#ts_personages').anystretch("sites/all/themes/ipad/css/images/Personages.jpg", {speed: 150});
	// $('#ts_video').anystretch("sites/all/themes/ipad/css/images/Video_bis.jpg", {speed: 150});
	// $('#ts_foto').anystretch("sites/all/themes/ipad/css/images/Foto.jpg", {speed: 150});
	// $('#ts_pers').anystretch("sites/all/themes/ipad/css/images/Pers.jpg", {speed: 150});
	// $('#ts_credits').anystretch("sites/all/themes/ipad/css/images/Credits.jpg", {speed: 150});
	// $('#ts_vertoning').anystretch("sites/all/themes/ipad/css/images/Vertoning.jpg", {speed: 150});



	// Cache selectors
	var lastId,
	    topMenu = $(".navbar-nav"),
	    topMenuHeight = topMenu.outerHeight(),
	// All list items
	    menuItems = topMenu.find("a"),
	// Anchors corresponding to menu items
	    scrollItems = menuItems.map(function(){
	      var item = $($(this).attr("href"));
	      if (item.length) { return item; }
	    });

	// Bind click handler to menu items
	// so we can get a fancy scroll animation
	menuItems.click(function(e){
	  var href = $(this).attr("href"),
	      offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
	  $('html, body').stop().animate({ 
	      scrollTop: offsetTop
	  }, 600, 'easeOutCirc');
	  e.preventDefault();
	});

	// Bind to scroll
	$(window).scroll(function(){
	   // Get container scroll position
	   var fromTop = $(this).scrollTop()+topMenuHeight;
	   
	   // Get id of current scroll item
	   var cur = scrollItems.map(function(){
	     if ($(this).offset().top < fromTop)
	       return this;
	   });
	   // Get the id of the current element
	   cur = cur[cur.length-1];
	   var id = cur && cur.length ? cur[0].id : "";
	   
	   if (lastId !== id) {
	       lastId = id;
	       // Set/remove active class
	       menuItems
	         .parent().removeClass("active")
	         .end().filter("[href=#"+id+"]").parent().addClass("active");
	   }                   
	});
});